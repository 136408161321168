//
// angular material
//
.cdk-overlay-container {
  z-index: 99996 !important;
}

.cdk-overlay-backdrop {
  //z-index: 99980 !important;
}
.cdk-global-overlay-wrapper {
  //z-index: 99980 !important;
}
.cdk-overlay-pane {
  //position: absolute!important;
  //overflow-y: auto!important;
  //width: 100% !important;
  //z-index: 1000 !important;
  padding: 10px;
}

.cdk-dialog-container {
  //width: 100%!important;
  //display: flex!important;
  //justify-content: center!important;
  //padding: 26px!important;
}