

:root {
  --color-text-light: #fff;
  --color-text: #041430;
  --color-text-green: #00adaa;
  --color-text-red: #ff5b23;
  --color-content-bg: #e8e8f0;

  .my-text-light {
    color: var(--color-text-light)!important;
  ;
  }
  .my-text {
    color: var(--color-text);
  }
  .my-blue {
    background: var(--color-text-green);
  }

  .H-32-700-DM_Sans {
    font-family: DM_Sans, serif !important;
    font-size: 32px;
    font-weight: 700 !important;
  }
  .H-28-700-DM_Sans {
    font-family: DM_Sans, serif !important;
    font-size: 28px;
    font-weight: 700;
  }
  .H-16-700-DM_Sans {
    font-family: DM_Sans, serif !important;
    font-size: 16px;
    font-weight: 700;
  }

  .H-18-700-DM_Sans {
    font-family: DM_Sans, serif !important;
    font-size: 18px;
    font-weight: 700;
  }

  .H-18-400-DM_Sans {
    font-family: DM_Sans, serif !important;
    font-size: 18px;
    font-weight: 400;
  }
  .H-16-400-DM_Sans {
    font-family: DM_Sans, serif !important;
    font-size: 16px;
    font-weight: 400;
  }
  .H-22-700-DM_Sans {
    font-family: DM_Sans, serif !important;
    font-size: 22px;
    font-weight: 700 !important;
  }
  .H-20-700-DM_Sans {
    font-family: DM_Sans, serif !important;
    font-size: 20px;
    font-weight: 700 !important;
  }

  .H-14-700-DM_Sans {
    font-family: DM_Sans, serif !important;
    font-size: 14px;
    font-weight: 700;
  }
  .H-14-400-DM_Sans {
    font-family: DM_Sans, serif !important;
    font-size: 14px;
    font-weight: 400;
  }

  .H-13-700-DM_Sans {
    font-family: DM_Sans, serif !important;
    font-size: 13px;
    font-weight: 700;
  }
  .H-9-500-DM_Sans {
    font-family: DM_Sans, serif !important;
    font-size: 9px;
    font-weight: 500;
  }


  .input {
    padding: 18px 16px;
    gap: 10px;
    height: 55px;
    border-radius: 8px 8px 0 0;
    background: #edf0f7;
    border: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    font-family: nunito, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: -0.04em;
    color: #66738f;
  }
  .input:focus {
    outline: none;
    box-shadow: none;
  }
  .tab-content {
    min-height: 83vw;
    border-top: none;
  }


}
.ia-button{
  background: linear-gradient(93.54deg, #041430 34.01%, #1F425E 55.74%, #68BDDA 121.61%);
  border: 1px solid #f5f5f5;
  width: 100%;
  height: 51px;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  padding: 10px 0;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items:center;
}
