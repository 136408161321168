.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
  background: var(--backdrop-color);
  backdrop-filter: blur(2px);
  z-index: 99998 !important;
}

div:where(.swal2-icon) {
	margin: 1.5em auto .6em;
}

div:where(.swal2-container) div:where(.swal2-popup) {
  padding: 9px 0 33px !important;
  border-radius: var(--radius) !important;
  width: 30rem !important;
  font-size: 0.9rem !important;
  scale: 0.92;
}

.swal2-actions {
  @apply gap-2 pt-3.5;
}

div:where(.swal2-container) .swal2-timer-progress-bar-container {
  @apply w-[95%] right-0 bottom-0 left-3 rounded-br-md rounded-bl-md dark:bg-gray-400 #{!important};
}

div:where(.swal2-container) .swal2-html-container {
	margin: 1em 2.6em .3em;
}