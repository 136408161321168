:root {
  @font-face {
    font-family: DM_Sans;
    font-style: normal;
    src: url('/assets/fonts/DM_Sans/DMSans-Regular.ttf');
    font-display: swap;
    font-weight: 400;
  }
  @font-face {
    font-family: DM_Sans;
    font-style: normal;
    src: url('/assets/fonts/DM_Sans/DMSans-Bold.ttf');
    font-display: swap;
    font-weight: 700;
  }
  @font-face {
    font-family: DM_Sans;
    font-style: italic;
    src: url('/assets/fonts/DM_Sans/DMSans-Italic.ttf');
    font-display: swap;
    font-weight: 400;
  }

  --font-sans: 'DM_Sans', 'sans-serif';
  --backdrop-color: rgba(146, 213, 251, 0.18);

  --muted: 229 13% 67%;
  --muted-foreground: 0 0% 98%;

  --card:0 0% 93%;
  --card-foreground: 240 10% 3.9%;
  --destructive: 0 73.2% 62.2%;
  --destructive-foreground: 60 9.1% 97.8%;
  --radius: 0.5rem;
  --popover: 0 0% 100%;
  --popover-foreground: 0 0% 3.9%;

  --secondary: 218 85% 10% ;
  --secondary-foreground: 221 51% 16%;
  --accent: 210 40% 96.1%;
  --accent-foreground: 222.2 47.4% 11.2%;
  --border: 214.3 31.8% 91.4%;
  --input: 214.3 31.8% 91.4%;
}

:root .dark,
:root.dark {
  --backdrop-color: rgba(41, 54, 91, 0.241);

  --muted: 230 28% 83%;
  --muted-foreground: 220 8.9% 46.1%;

  --card: 215 28% 17%;
  --card-foreground: 0 0% 95%;
  --popover: 217 19% 27%;
  --popover-foreground: 167 29% 94%;

  --secondary: 218 35% 12%;
  --secondary-foreground: 210 40% 98%;
  --accent: 218 35% 9%;
  --accent-foreground: 210 40% 98%;
  --border: 218 35% 9%;
  --input: 216 10% 30%;
}

:root .theme-default {
  --background: 0 0% 100%;
  --foreground: 20 14.3% 4.1%;
  --primary:    43 78% 66%;
  --primary-foreground: 218 85% 10%;
  --ring: 20 14.3% 4.1%;
}

.dark .theme-default,
.dark.theme-default {
  --background: 20 14.3% 4.1%;
  --foreground: 60 9.1% 97.8%;
  --primary: 179 100% 34%;
  --primary-foreground: 60 9.1% 97.8%;
  --ring: 24 5.7% 82.9%;
}
