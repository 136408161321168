@import '@ng-select/ng-select/scss/default.theme';

@mixin custom-select-container {
  // @apply flex h-10 w-full rounded-md border border-input bg-transparent py-2 text-sm font-normal text-foreground ring-offset-background disabled:cursor-not-allowed disabled:opacity-50 dark:bg-gray-500/30;
  font-family: DM_Sans, serif !important;
  line-height: 120%;
  @apply h-[55px] rounded-[8px_8px_0_0] border-0 bg-[#edf0f7] py-4 text-[16px] font-medium tracking-[-0.04em] text-[#66738f] focus-visible:ring-1 focus-visible:ring-gray-300 dark:bg-[#edf0f7] #{!important}
}

@mixin custom-dropdown-optgroup-marked-selected {
  @apply bg-primary/90 text-primary-foreground dark:bg-primary/85 #{!important};
}

@mixin valid-invalid-field {
  //padding-right: calc(1.5em + 1.5rem);
  //background-repeat: no-repeat;
  //background-position: right calc(0.375em + 0.375rem) center;
  //background-size: calc(0.75em + 0.75rem) calc(0.75em + 0.75rem);
}

@mixin valid-field {
  background-image: none;
  // @apply border-green-500;
  //border-color: #50CD89;
  //background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2350CD89' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
}
@mixin invalid-field {
  @apply border-destructive;
  background-image: none;
  //background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23F1416C'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23F1416C' stroke='none'/%3e%3c/svg%3e");
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  border-color: transparent !important;
  box-shadow: none !important;
}
.ng-select.ng-select-multiple,
  .ng-select-container,
  .ng-value-container,
  .ng-value,
  .ng-value-icon:hover {
  background-color: transparent !important;
}
.ng-select.ng-select-multiple,
  .ng-select-container,
  .ng-value-container,
  .ng-value,
  .ng-value-icon.left {
  border-right: transparent !important;
}
.ng-select.ng-select-multiple,
  .ng-select-container,
  .ng-value-container,
  .ng-value,
  .ng-value-icon.right {
  border-left: transparent !important;
}

.ng-select {
  font-size: 0.9rem;

  &.ng-select-disabled {
    @apply bg-gray-500/10 opacity-50 #{!important};
    .ng-input input {
      @apply cursor-not-allowed #{!important};
    }
  }

  &:has(.ng-input input:focus) {
    @apply flex w-full rounded-md border border-input bg-transparent text-sm font-normal text-foreground outline-none ring-2 ring-ring ring-offset-0 ring-offset-background disabled:cursor-not-allowed disabled:opacity-50 dark:bg-gray-500/30 #{!important};
  }

  .ng-clear-wrapper {
    cursor: pointer;
    position: relative;
    width: 17px;
    -webkit-user-select: none;
    user-select: none;
    transform: translateX(-4%);
    text-align: center;
  }

  .ng-input input {
    @apply h-[44px] p-[5px] text-foreground #{!important};
  }

  &.ng-select-multiple {
    .ng-select-container {
      @include custom-select-container;

      .ng-placeholder {
        @apply top-[19px] pl-2 text-muted dark:text-muted-foreground #{!important};
      }

      .ng-value {
        @include custom-dropdown-optgroup-marked-selected;
        font-size: 14px;
      }

      .ng-value-container {
        .ng-value {
          @apply rounded-md p-0.5 font-sans #{!important};
        }
      }
    }
  }

  &.ng-select-single {
    .ng-select-container {
      @include custom-select-container;
      @apply pb-1 pl-1.5 #{!important};

      .ng-input input {
        @apply top-2 #{!important};
      }

      .ng-placeholder {
        @apply bottom-0 py-2 text-muted dark:text-muted-foreground #{!important};
      }
    }
  }

  &.is-valid {
    .ng-select-container {
      @include valid-invalid-field;
      @include valid-field;
    }
  }

  &.is-invalid {
    .ng-select-container {
      @include valid-invalid-field;
      @include invalid-field;
    }

    &:has(.ng-input input:focus) {
      @apply border-none ring-destructive #{!important};
    }
  }
}

.ng-dropdown-panel {
  .ng-dropdown-panel-items {
    .ng-option {
      @apply bg-popover text-popover-foreground hover:bg-accent hover:text-accent-foreground;

      &.ng-option-marked {
        @apply bg-accent text-accent-foreground;
      }

      &.ng-option-selected {
        @apply bg-accent text-accent-foreground #{!important};

        &.ng-option-marked {
          @apply bg-gray-400/10 text-accent-foreground dark:bg-accent/90 #{!important};
        }
      }
    }
  }
}
