@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .hide-all-except-first-child {
    & *:not(:first-child) {
      @apply hidden;
    }
  }
}

@import './plugins/init';
@import './global-syle.scss';
