.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  @apply bg-primary border-primary text-primary-foreground focus:bg-primary focus:border-primary focus:text-primary-foreground hover:bg-primary/80 hover:text-primary-foreground #{!important};
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)) {
  box-shadow: -10px 0 0 hsl(var(--popover)) !important;
}

.flatpickr-day.inRange {
  border-radius: 0;
  box-shadow:
    -5px 0 0 hsl(var(--popover)),
    5px 0 0 hsl(var(--popover)) !important;
}

.flatpickr-calendar {
  -webkit-box-shadow:
    1px 0 0 hsl(var(--popover)),
    -1px 0 0 hsl(var(--popover)),
    0 1px 0 hsl(var(--popover)),
    0 -1px 0 hsl(var(--popover)),
    0 3px 13px rgba(0, 0, 0, 0.08) !important;
  box-shadow:
    1px 0 0 hsl(var(--popover)),
    -1px 0 0 hsl(var(--popover)),
    0 1px 0 hsl(var(--popover)),
    0 -1px 0 hsl(var(--popover)),
    0 3px 13px rgba(0, 0, 0, 0.08) !important;
  @apply bg-popover dark:border-gray-900 text-popover-foreground #{!important};
}

.flatpickr-months .flatpickr-month,
span.flatpickr-weekday {
  @apply text-popover-foreground #{!important};
}

.flatpickr-day {
  @apply text-popover-foreground dark:hover:bg-gray-100 dark:hover:text-gray-700 #{!important};
}

.flatpickr-day.inRange {
  @apply dark:bg-gray-100 #{!important};
}

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  @apply dark:bg-gray-600 border-0 rounded-md #{!important};
}

.flatpickr-calendar.arrowTop::after,
.flatpickr-calendar.arrowTop::before {
  @apply border-b-gray-900 #{!important};
}

.flatpickr-day {
  @apply border-none dark:hover:bg-gray-400/10 dark:focus:bg-gray-500/20 dark:hover:text-white dark:focus:text-white #{!important};
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  @apply text-popover-foreground text-lg rounded-sm hover:bg-gray-600/10 dark:hover:bg-gray-400/10 flex items-center justify-center align-middle #{!important};
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  @apply bg-popover border-slate-900 #{!important};
}
