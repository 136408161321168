@import '@angular/cdk/overlay-prebuilt.css';


/* You can add global styles to this file, and also import other style files */
@import "./assets/sass/style.scss";
@import "./assets/sass/themes.scss";

.severo{
  background: linear-gradient(108.28deg, #FF8282 27.65%, #E00606 142.68%);
}
.moderado{
  background: linear-gradient(109.62deg, #FFE53B 3.18%, #FF7125 150.51%);
}
.leve{
  background: linear-gradient(104.58deg, #ADEE7C 0.42%, #77C13F 103.97%, #51832B 148.45%);
}
.sin-riesgo{
  background: linear-gradient(0deg, #DEDEDE 70%, #B3B3B3 100%, #737373);

}
